import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { capitalize } from "lodash"

import Category from "../components/category"

export const ReadMore = ({ children, className, ...props }) => (
  <Link
    className={`border border-mineShaft inline-block px-8 py-2 font-mono text-center text-sm ${className}`}
    {...props}
  >
    {children}
  </Link>
)

export const ArticlePreview = ({ img, title, to, category }) => (
  <div className="w-full md:w-1/2 xl:w-1/3 px-4 sm:px-6 mt-12">
    <article>
      <div className="overflow-hidden" style={{ height: "235px" }}>
        <Link to={to}>
          <Img
            className="h-full"
            fluid={img}
            title={title}
            imgStyle={{ objectFit: "cover" }}
          />
        </Link>
      </div>
      <Category slug={category} className="my-5">
        {capitalize(category)}
      </Category>
      <Link to={to}>
        <h2 className="text-xl font-medium">{title}</h2>
      </Link>
    </article>
  </div>
)

export const FeaturedArticlePreview = ({
  img,
  title,
  summary,
  to,
  category,
}) => (
  <div className="w-full px-4 sm:px-6 mt-10">
    <article className="flex flex-wrap">
      <Link className="w-full lg:w-1/2" to={to}>
        <Img
          className="h-full"
          fluid={img}
          title={title}
          imgStyle={{ objectFit: "contain" }}
        />
      </Link>
      <div className="mt-5 lg:mt-0 w-full lg:w-1/2 lg:pl-8 flex flex-col items-start">
        <Category slug={category}>{capitalize(category)}</Category>
        <h2 className="font-medium text-xl lg:text-2xl xl:text-4xl my-5 lg:my-8 xl:mt-12 leading-tight">
          {title}
        </h2>
        <p className="font-mono color-red text-base xl:text-lg text-red">
          {summary}
        </p>
        <div className="flex-grow" />
        <ReadMore className="mt-8" to={to}>
          Read More
        </ReadMore>
      </div>
    </article>
  </div>
)
