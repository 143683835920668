import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { FeaturedArticlePreview, ArticlePreview } from "../components/article"

const Blog = ({ data }) => {
  const posts = data.allFile.edges.map(edge => edge.node.childMarkdownRemark)

  return (
    <Layout>
      <SEO title="Blog" />
      <div className="flex flex-wrap w-full lg:px-8">
        {posts.map((post, i) => {
          if (i === 0) {
            return (
              <FeaturedArticlePreview
                to={post.fields.slug}
                key={i}
                title={post.frontmatter.title}
                img={
                  post.frontmatter.banner &&
                  post.frontmatter.banner.childImageSharp.fluid
                }
                summary={post.excerpt}
                category={post.frontmatter.category}
              />
            )
          }
          return (
            <ArticlePreview
              to={post.fields.slug}
              key={i}
              title={post.frontmatter.title}
              img={
                post.frontmatter.banner &&
                post.frontmatter.banner.childImageSharp.fluid
              }
              category={post.frontmatter.category}
            />
          )
        })}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allFile(
      filter: { sourceInstanceName: { eq: "blog" }, extension: { eq: "md" } }
      sort: { fields: childMarkdownRemark___frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              category
              date(formatString: "MMMM DD, YYYY")
              title
              banner {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            excerpt
            fields {
              slug
            }
          }
        }
      }
    }
  }
`

export default Blog
